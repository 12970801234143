<template>
    <section id="realisation-app" v-if="visible">
        <button id="close">
            <svg aria-hidden="true">
                <use xlink:href="#icon-close" />
            </svg>
        </button>
        <iframe width="100%" height="100%" v-if="appLink" :src="appLink"></iframe>
    </section>
</template>

<script>
export default {
    name: "RealisationApp",
    props: ["appLink", "visible"],
}
</script>

<style scoped>
#realisation-app {
    position: fixed;
    top: 0;
    left: 0;
    padding: 55px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    z-index: 2;
}

iframe {
        box-shadow: 0px 0px 10px 10px var(--black_trans);
}

#close {
    border: none;
    outline: none;
    background: none;
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.6;
    cursor: pointer;
    transition: 0.15s linear;
}

#close:hover {
    opacity: 1;
}

#close svg {
    width: 1em;
    height: 1em;
    font-size: 32px;
    vertical-align: -0.15em;
    overflow: hidden;
}
</style>
