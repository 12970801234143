<template>
  <section>
    <div class="container">
      <div class="left">
        <div class="photo">
          <img src="@/assets/img/guillaume.jpg" alt="Guillaume" />
        </div>

        <div class="owner">
          <p>Qui suis-je ? Je suis avant tout un créatif qui vous propose des projets de A à Z. Pourquoi travailler avec moi ? Vous trouverez en moi un technicien expérimenté mais avant tout un véritable soutient pour la création de vos projets. Ayant l'habitude de collaborer avec des architectes confirmés, on dit de moi que je vous fais gagner un temps précieux grâce à mes créations numériques.</p>

          <p>Comment en suis-je arrivé là ? Après 10 ans d'expérience, un BT Collaborateur d'Architecte et un Master de réalisateur numérique, je suis fier de dire que vous trouvez en moi un véritable allié pour vos projets numériques.</p>
        </div>
      </div>

      <div class="right">
        <h2>Contact</h2>
        <ul class="text-light">
          <li v-for="contact in contacts" v-bind:key="contact.icon">
            <i :class="['fas', `fa-${contact.icon}`]"></i>
            <span v-if="contact.icon === 'map-marker-alt'">{{ contact.content }}</span>
            <a
              :href="`callto:${contact.content}`"
              v-if="contact.icon === 'phone'"
            >{{ contact.content }}</a>
            <a
              :href="`mailto:${contact.content}`"
              v-if="contact.icon === 'envelope'"
            >{{ contact.content }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "Contact",
  data() {
    return {
      contacts: [{
        icon: "map-marker-alt",
        content: "Reims"
      }, {
        icon: "phone",
        content: "+33 (6) 37 95 82 90"
      },
      {
        icon: "envelope",
        content: "guillaume.cunis@gmail.com"
      }]
    }
  }
}
</script>

<style scoped>
section {
  padding: 55px;
}

section .container {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
}

section .container .left {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
}

section .container .photo img {
  width: 150px;
}

section .container .owner {
  color: var(--black);
  font-size: 18px;
  text-align: left;
  max-width: 690px;
  padding: 21px 13px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 21px;

  max-width: 520px;
}

.right {
  padding: 34px 0px;
}

.right h2 {
  color: var(--accent);
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  text-transform: uppercase;
}

.right ul {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.right ul li i {
  font-size: 18px;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  text-align: center;
  color: var(--black);
}

.right ul li a,
.right ul li span {
  margin-left: 13px;
  font-size: 18px;
  text-align: left;
  color: var(--black);
  text-decoration: none;
  transition: color ease 0.25s;
}

.right ul li a:hover {
  color: var(--accent);
}

@media only screen and (min-width: 560px) {
  .right ul {
    margin-top: 13px;
    display: flex;
    flex-direction: row;
    gap: 55px;
  }
}

@media only screen and (min-width: 900px) {
  section .container {
    flex-direction: row;
  }

  section .container .left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .right {
    padding: 34px 21px;
    width: 370px;
  }

  .right ul {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}
</style>
