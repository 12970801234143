<template>
  <div id="slideshow">
    <Splide :options="{ rewind: true, autoplay: true, cover: true, heightRatio: 1 / 3.14750 }">
      <SplideSlide v-for="image in images" v-bind:key="image.id">
        <img v-bind:src="image.path" v-bind:alt="image.title" />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';


const getImageUrl = function (filename) {
  return require(`@/assets/img/slideshow/${filename}`)
}



export default {
  name: 'SlideShow',
  components: {
    Splide,
    SplideSlide
  },
  data() {
    return {
      images: [{
        id: 1,
        path: getImageUrl("SlideShow01.jpg"),
        title: "Image 1",
      },
      {
        id: 2,
        path: getImageUrl("SlideShow02.jpg"),
        title: "Image 2",
      },
      {
        id: 3,
        path: getImageUrl("SlideShow03.jpg"),
        title: "Image 3",
      },
      {
        id: 4,
        path: getImageUrl("SlideShow04.jpg"),
        title: "Image 4",
      },
      {
        id: 5,
        path: getImageUrl("SlideShow05.jpg"),
        title: "Image 5",
      },
      {
        id: 6,
        path: getImageUrl("SlideShow06.jpg"),
        title: "Image 6",
      },

      ]
    }
  }
}
</script>

<style scoped>
#slideshow img {
  max-width: 100%;
}
</style>
