<template>
    <li>
        <VR v-if="icon === 'vr'"></VR>
        <View360 v-if="icon === '360'"></View360>
        <Cube v-if="icon === 'cube'"></Cube>
        <Cube360 v-if="icon === 'cube360'"></Cube360>
        <p>
            <span class="title">{{ title }} :</span>
            {{ content }}
        </p>
    </li>
</template>


<script>
import VR from './VR.vue';
import View360 from './View360.vue';
import Cube from './Cube.vue';
import Cube360 from './Cube360.vue';

export default {
    name: "Service",
    props: ["title", "content", "icon"],
    components: { VR, View360, Cube, Cube360 },
    data() {
        return {}
    }
}
</script>

<style scoped>
li {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    flex: 1 1 0px;
}

li p {
    text-align: center;
    font-size: 21px;
    line-height: 1.25em;
}

li p .title {
    font-weight: bold;
}
</style>
