<template>
  <header id="topNav">
    <div class="headerLeft">
      <svg
        width="110"
        height="100"
        viewBox="0 0 110 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.3 79.9H5.20001V78.6H16.9V79.9H11.8V93.1H10.3V79.9Z" fill="#728699" />
        <path
          d="M27.8 89.2H19.7L17.9 93.1H16.3L23 78.6H24.5L31.2 93.1H29.5L27.8 89.2ZM27.2 88L23.7 80.2L20.3 88H27.2Z"
          fill="#728699"
        />
        <path
          d="M45 83.5C45 86.6 42.7 88.5 39 88.5H35.1V93.1H33.6V78.6H39C42.7 78.6 45 80.4 45 83.5ZM43.4 83.5C43.4 81.2 41.9 79.9 39 79.9H35.1V87.2H39C41.9 87.2 43.4 85.8 43.4 83.5Z"
          fill="#728699"
        />
        <path
          d="M58.6 91.8V93.1H48.3V78.6H58.3V79.9H49.8V85.1H57.4V86.4H49.8V91.8H58.6Z"
          fill="#728699"
        />
        <path d="M65 79.9H59.9V78.6H71.7V79.9H66.6V93.1H65V79.9Z" fill="#728699" />
        <path
          d="M73.7 86.9V78.6H75.3V86.8C75.3 90.3 76.9 91.9 79.7 91.9C82.5 91.9 84.2 90.3 84.2 86.8V78.6H85.7V86.9C85.7 91.1 83.4 93.2 79.7 93.2C76 93.2 73.7 91.1 73.7 86.9Z"
          fill="#728699"
        />
        <path
          d="M105.4 78.6V93.1H104V81.5L98.3 91.3H97.5L91.8 81.5V93.1H90.3V78.6H91.6L97.9 89.3L104.2 78.6H105.4Z"
          fill="#728699"
        />
        <path
          d="M47.8 37.6C49.7553 36.9387 51.633 36.0669 53.4 35C49.0258 31.944 45.4579 27.8728 43.0022 23.1354C40.5464 18.3981 39.2761 13.1359 39.3 7.79998C39.2834 7.06522 39.3168 6.33019 39.4 5.59998L33.9 6.09998V7.79998C33.9072 13.4797 35.1549 19.0892 37.5558 24.2365C39.9567 29.3838 43.4529 33.9446 47.8 37.6V37.6Z"
          fill="#728699"
        />
        <path
          d="M53.7 41.6999L55 42.3999L56.3 41.6999C61.4795 38.7406 65.9235 34.6492 69.3001 29.7315C72.6767 24.8138 74.8987 19.1968 75.8 13.2999L70.4 12.7999C69.5074 18.5998 67.107 24.0634 63.4391 28.6439C59.7712 33.2244 54.9644 36.7611 49.5 38.8999C50.845 39.9132 52.2473 40.8481 53.7 41.6999Z"
          fill="#728699"
        />
        <path
          d="M82.6 12.3C81.3724 10.1352 79.8957 8.1215 78.2 6.29998L70.7 5.59998V7.79998C70.7 8.89998 70.7 9.99998 70.6 11C74.6 11.3 78.6 11.8 82.6 12.3Z"
          fill="#728699"
        />
        <path
          d="M80.9 6.59998C84.2769 10.7472 86.6085 15.6446 87.6987 20.8804C88.7889 26.1162 88.6059 31.5373 87.165 36.6876C85.7241 41.838 83.0675 46.567 79.4186 50.477C75.7698 54.387 71.2353 57.3636 66.1967 59.1564C61.158 60.9493 55.7625 61.5059 50.464 60.7796C45.1654 60.0533 40.1187 58.0652 35.7484 54.9826C31.3781 51.9 27.8119 47.8129 25.3498 43.0653C22.8878 38.3176 21.6018 33.0481 21.6 27.7C21.5964 23.1948 22.5152 18.7366 24.3 14.6L18.1 15.7C16.8221 19.5709 16.1804 23.6236 16.2 27.7C16.1842 37.3579 19.7709 46.6745 26.259 53.8286C32.747 60.9827 41.6699 65.4599 51.2835 66.3849C60.897 67.31 70.5099 64.6165 78.2431 58.831C85.9763 53.0454 91.2738 44.5837 93.1 35.1C93.5853 32.6637 93.8198 30.1841 93.8 27.7C93.8163 20.6441 91.878 13.7215 88.2 7.69998L80.9 6.59998Z"
          fill="#728699"
        />
        <path
          d="M32.3 11.6999C32.1338 10.4069 32.0669 9.10313 32.1 7.79993V6.29993C28.6 6.59993 25.2 7.09993 21.8 7.69993C20.6024 9.59484 19.5974 11.6049 18.8 13.6999L25.8 12.5999C27.9 12.1999 30.1 11.9999 32.3 11.6999Z"
          fill="#728699"
        />
      </svg>

      <div class="jobTitle text-primary">{{ jobDescription }}</div>
    </div>

    <nav>
      <a
        href="#realisations"
        v-on:click="changeActiveAnchor"
        :class="getActiveClass('realisations')"
      >Réalisations</a>
      <a
        href="#services"
        v-on:click="changeActiveAnchor"
        :class="getActiveClass('services')"
      >Services</a>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'TopNav',
  props: ["activeAnchor"],
  data() {
    return {
      jobDescription: "Infographiste 3D",
    }
  },
  methods: {
    getActiveClass(anchor) {
      return this.activeAnchor === anchor ? "active" : "";
    },
    changeActiveAnchor(event) {
      const anchor = event.target.getAttribute('href').substring(1);
      this.$emit('nav', anchor);
    }
  }
}
</script>

<style scoped>
header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 22px 55px;
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 34px;
}

.headerLeft svg {
  width: 89px;
  height: 89px;
}

.headerLeft svg path {
  fill: var(--primary);
}

.brand svg path {
  fill: var(--primary);
}

.jobTitle {
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
}

header nav {
  display: none;
}

@media only screen and (min-width: 768px) {
  header nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 34px;
  }

  header nav a {
    font-size: 21px;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--primary);
    transition: color ease 0.25s;
  }

  header nav a.active {
    color: var(--accent);
  }

  header nav a:hover {
    color: var(--accent);
  }
}

@media only screen and (min-width: 1280px) {
  .headerLeft {
    gap: 123px;
  }

  .headerLeft svg {
    width: unset;
    height: unset;
  }
}
</style>
