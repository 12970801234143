<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="65" viewBox="0 0 75 65">
        <g>
            <path
                class="a"
                d="M64.7,35v1.7a6.4,6.4,0,0,1-6.5,6.5H47.6a7.7,7.7,0,0,1-5.7-2.5,5.4,5.4,0,0,0-3.4-1.8,5,5,0,0,0-3.9,1.3A9,9,0,0,1,30.3,43l-2.2.2H18.2c-4.2,0-6.9-2.7-6.9-6.9V35H7.7a2.6,2.6,0,0,1-2.6-2.6V24.6A2.6,2.6,0,0,1,7.7,22h3.6V20.5c0-4.1,2.8-6.8,6.9-6.8H32.1c.7,0,.9-.2.9-.8a17.1,17.1,0,0,1,0-3.2,2.7,2.7,0,0,1,2.8-2.8h4.4A2.8,2.8,0,0,1,43,9.8a28.1,28.1,0,0,1,0,2.9c0,.8.2,1,.9,1H58a6.5,6.5,0,0,1,6.5,4.9c.1.6.1,1.3.2,2V22h3c2.2,0,3.2,1,3.2,3.1v6.8A2.8,2.8,0,0,1,67.8,35ZM38,15.1H18.2c-3.3,0-5.4,2.1-5.4,5.5-.1,5.2-.1,10.5,0,15.7,0,3.4,2,5.5,5.4,5.5h9.4a7.5,7.5,0,0,0,5.8-2.4,9.4,9.4,0,0,1,2.3-1.6,6.2,6.2,0,0,1,6.9,1.6,7.5,7.5,0,0,0,5.8,2.4h9.5c3.2-.1,5.3-2.1,5.3-5.2.1-5.4,0-10.8,0-16.2a5.1,5.1,0,0,0-2-4.3,6.2,6.2,0,0,0-3.5-1Zm26.7,8.3v9.7a.7.7,0,0,0,.5.4h2.9a1.2,1.2,0,0,0,1.3-1.2V24.6a1,1,0,0,0-1.1-1.1Zm-53.4,0H7.9c-.8.1-1.3.5-1.3,1.3v7.5a1.2,1.2,0,0,0,1.3,1.3h2.6c.7.1.8-.2.8-.8V23.4Zm26.6-9.7H41c.4,0,.6-.1.6-.5V9.7a1.3,1.3,0,0,0-1.4-1.3H35.8a1.3,1.3,0,0,0-1.4,1.4v3.3a.5.5,0,0,0,.6.6Z"
            />
            <path
                class="a"
                d="M24.9,24.2c-.2.3-.3.6-.5.7-2,2.1-4.1,4.1-6.1,6.1-.4.4-.8.6-1.2.2s-.2-.8.2-1.2c2-2,4-4.1,6-6a1.4,1.4,0,0,1,1.1-.4C24.5,23.6,24.7,24,24.9,24.2Z"
            />
            <path
                class="a"
                d="M23.8,18.9a1.6,1.6,0,0,1-.4.7l-5.3,5.3c-.4.4-.8.6-1.2.1s-.2-.8.2-1.1l5.3-5.3a1,1,0,0,1,.9-.2C23.5,18.4,23.7,18.7,23.8,18.9Z"
            />
            <path
                class="a"
                d="M30,19.1l-.5.7c-.7.8-1.5,1.5-2.3,2.3s-.8.6-1.2.1-.2-.8.2-1.1,1.6-1.7,2.4-2.4a.8.8,0,0,1,.8-.2C29.6,18.5,29.8,18.9,30,19.1Z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "VR"
}
</script>

<style scoped>
li svg path {
    fill: var(--white);
}
</style>
