<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65">
        <path
            class="a"
            d="M33.9,9.7L12.4,21.5v22.6l21.5,14,21.5-13.8V21.5l-21.5-11.8Zm-1.3,44.8l-17.9-11.5V24.4l17.9,10.6v19.6Zm1.2-22L16,21.9l17.9-9.7,17.8,9.7-17.9,10.6Zm19.2,10.5l-18.1,11.6v-19.7l18.1-10.5v18.6Z"
        />
    </svg>
</template>

<script>
export default {
    name: "Cube"
}
</script>

<style scoped>
li svg path {
    fill: var(--white);
}
</style>
