<template>
  <TopNav v-on:nav="updateActiveAnchor" :activeAnchor="activeAnchor"></TopNav>
  <SlideShow></SlideShow>
  <Presentation></Presentation>
  <Realisations v-on:clickAppLink="openApp"></Realisations>
  <Services></Services>
  <Contact></Contact>
  <Footer></Footer>
  <RealisationApp v-on:click="closeApp" :appLink="currentAppLink" :visible="appVisible"></RealisationApp>
</template>

<script>
import TopNav from './components/TopNav.vue'
import SlideShow from './components/SlideShow.vue'
import Presentation from './components/Presentation.vue'
import Realisations from './components/Realisations/Realisations.vue'
import RealisationApp from "./components/Realisations/RealisationApp.vue";
import Services from './components/Services/Services.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    TopNav,
    SlideShow,
    Presentation,
    Realisations,
    RealisationApp,
    Services,
    Contact,
    Footer
  },
  data() {
    return {
      currentAppLink: null,
      appVisible: false,
      activeAnchor: null
    }
  },
  methods: {
    updateActiveAnchor(anchor) {
      this.activeAnchor = anchor;
    },
    openApp(appLink) {
      this.currentAppLink = appLink;
      this.appVisible = true;
    },
    closeApp() {
      this.currentAppLink = null;
      this.appVisible = false;
    }
  }
}
</script>


<style>
:root {
  --black: #404041;
  --black_trans: #40404191;
  --light-text: #a5a5a5;
  --white: #efefef;
  --primary: #728699;
  --primary-light: #869cb6;
  --accent: #2aaae2;
  --picto: #728699;
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300&display=swap");

* {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
}

body {
  font-family: Urbanist;
  font-weight: 300;
}

/* definitions */

.text-black {
  color: var(--black);
}

.text-light {
  color: var(--light-text);
}

.text-primary {
  color: var(--primary);
}

.text-accent {
  color: var(--accent);
}

.text-center {
  text-align: center;
}

h1 {
  font-size: 55px;
  font-weight: 300;
  margin: 21px 0 13px 0;
}

h2 {
  font-size: 34px;
  font-weight: 300;
  margin: 21px 0 13px 0;
}

h3 {
  font-size: 21px;
  font-weight: 300;
  margin: 21px 0 13px 0;
}

h4 {
  font-size: 13px;
  font-weight: 300;
  margin: 21px 0 13px 0;
}
</style>
