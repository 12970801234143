<template>
    <button class="grid-item">
        <img :src="realisation.src" :alt="realisation.alt" />
        <caption>
            <p class="title">{{ realisation.caption.title }}</p>
            <p class="description">{{ realisation.caption.text }}</p>
        </caption>
        <div class="realisation-links">
            <button class="realisation-link" v-on:click="showHQ">
                <i class="fas fa-layer-group"></i>
            </button>
            <button
                class="realisation-link app-iframe"
                v-if="realisation.appLink"
                v-on:click="openApp"
            >
                <i class="fas fa-cube"></i>
            </button>
            <a
                :href="realisation.appLink"
                target="blank"
                class="realisation-link app-link"
                v-if="realisation.appLink"
            >
                <i class="fas fa-cube"></i>
            </a>
        </div>
    </button>

    <vue-easy-lightbox
        moveDisabled
        v-if="realisation.hq"
        :visible="hqIsVisible"
        :imgs="realisation.hq"
        :index="0"
        @hide="closeHQ"
    >
        <template v-slot:toolbar></template>
    </vue-easy-lightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    name: "RealisationItem",
    props: ["realisation"],
    components: {
        VueEasyLightbox
    },
    data() {
        return {
            hqIsVisible: false
        }
    },
    methods: {
        showHQ() {
            this.hqIsVisible = true;
        },
        closeHQ() {
            this.hqIsVisible = false;
        },
        openApp() {
            this.$parent.$emit('clickAppLink', this.realisation.appLink)
        }
    }
}
</script>


<style scoped>
.grid-item {
    overflow: hidden;
    position: relative;
    background: none;
    border: none;
    padding: 0;
    outline: none;
}

.grid-item img {
    max-width: 100%;
    transition: all 0.2s;
}

.grid-item caption {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 5px 13px;
    background: rgba(0, 0, 0, 0.4);
}

.grid-item caption .title {
    text-align: left;
    margin-bottom: 13px;
    color: white;
    font-weight: bold;
}

.grid-item caption .description {
    text-align: left;
    color: white;
}

.grid-item:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0) 0%,
        rgba(255, 255, 255, 0.90) 100%
    );
    opacity: 0;
    transition: all ease 0.2s;
    z-index: 1;
}

.realisation-links {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 13px;
    padding: 5px;
}

.realisation-link {
    font-size: 21px;
    color: var(--accent);
    opacity: 1;
    transition: all ease 0.2s;
    background: rgba(0, 0, 0, 0.4);
    padding: 5px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.app-iframe {
    display: none;
}
.app-link {
    display: inherit;
    text-decoration: none;
}

@media only screen and (min-width: 1280px) {
    .app-iframe {
        display: inherit;
    }
    .app-link {
        display: None;
    }
}

@media only screen and (min-width: 768px) {
    .realisation-links {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 21px;
    }

    .realisation-link {
        font-size: 34px;
        color: var(--accent);
        opacity: 0;
        transition: all ease 0.2s;
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
    }

    .grid-item:hover img {
        transform: scale(1.1);
    }

    .grid-item caption {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: all ease 0.2s;
        z-index: 2;
        padding: 5px 13px;
        background: unset;
    }

    .grid-item:hover caption {
        opacity: 1;
    }

    .grid-item caption .title {
        color: var(--black);
    }

    .grid-item caption .description {
        color: var(--black);
    }

    .grid-item:hover:after {
        opacity: 1;
    }
    .grid-item:hover .realisation-links .realisation-link {
        opacity: 1;
    }
}
</style>
