<template>
    <section id="realisations">
        <h1 class="text-accent">réalisations</h1>
        <div id="realisationGrid">
            <RealisationItem
                v-for="(realisation, realisationIndex) in realisations"
                v-bind:key="realisationIndex"
                v-bind:realisation="realisation"
            ></RealisationItem>
        </div>
    </section>
</template>


<script>
import RealisationItem from "./RealisationItem.vue";

const getImageUrl = function (filename) {
    return require(`@/assets/img/${filename}`)
}

export default {
    name: "Realisation",
    components: { RealisationItem },
    data() {
        return {
            realisations: [{
                src: getImageUrl("realisations/A1.jpg"),
                alt: "real1",
                caption: {
                    title: "Promenades Reims",
                    text: "Implantation d’un kiosque de restauration sur les promenades de Reims. "
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/A1_HQ01.jpg"),
                        alt: "A1_HQ01",
                        title: "Promenades Reims - Générale"
                    },
                    {
                        src: getImageUrl("realisations/HQ/A1_HQ02.jpg"),
                        alt: "A1_HQ02",
                        title: "Promenades Reims - Détail"
                    },
                    {
                        src: getImageUrl("realisations/HQ/A1_HQ03.jpg"),
                        alt: "A1_HQ03",
                        title: "Promenades Reims - Dessus"
                    }
                ],
            },
            {
                src: getImageUrl("realisations/A2.jpg"),
                alt: "real2",
                caption: {
                    title: "Reims",
                    text: "Rénovation et extension d’une maison d’habitation - Architecte : REGULAR ARCHITECTURE "
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/A2_HQ01.jpg"),
                        alt: "A2_HQ01",
                        title: "Reims - Rénovation - Rue"
                    },
                    {
                        src: getImageUrl("realisations/HQ/A2_HQ02.jpg"),
                        alt: "A2_HQ02",
                        title: "Reims - Rénovation - Jardin"
                    },
                ],
                                appLink: "https://client.tapetum.fr/A2/"
            },
            {
                src: getImageUrl("realisations/A3.jpg"),
                alt: "real3",
                caption: {
                    title: "Brugny-Vaudancourt",
                    text: "Construction d’une maison d’habitation - Architecte : REGULAR ARCHITECTURE "
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/A3_HQ.jpg"),
                        alt: "A3_HQ",
                        title: "Brugny-Vaudancourt"
                    },
                    {
                        src: getImageUrl("realisations/HQ/A3_HQ01.jpg"),
                        alt: "A3_HQ01",
                        title: "Brugny-Vaudancourt"
                    },
                    {
                        src: getImageUrl("realisations/HQ/A3_HQ02.jpg"),
                        alt: "A3_HQ02",
                        title: "Brugny-Vaudancourt"
                    },
                ],
                appLink: "https://client.tapetum.fr/A3/"
            },
            {
                src: getImageUrl("realisations/B1.jpg"),
                alt: "real3",
                caption: {
                    title: "Belle Epoque",
                    text: "Résidence proche de Deauville - Architecte : REGULAR ARCHITECTURE"
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/B1_HQ.jpg"),
                        alt: "B1_HQ",
                        title: "Perspective Interieure"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ02.jpg"),
                        alt: "B01_HQ02",
                        title: "Perspective exterieure Route de Villers "
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ03.jpg"),
                        alt: "B01_HQ03",
                        title: "Perspective exterieure Rue Pierre Velghe"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ04.jpg"),
                        alt: "B01_HQ04",
                        title: "Ecorché 003"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ05.jpg"),
                        alt: "B01_HQ05",
                        title: "Ecorché 101A"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ06.jpg"),
                        alt: "B01_HQ06",
                        title: "Ecorché 203B"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ07.jpg"),
                        alt: "B01_HQ07",
                        title: "Ecorché 205B"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ08.jpg"),
                        alt: "B01_HQ08",
                        title: "Ecorché 301A"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B01_HQ09.jpg"),
                        alt: "B01_HQ09",
                        title: "Facades"
                    },
                ],
                appLink: "https://client.tapetum.fr/BelleEpoque/"
            },
            {
                src: getImageUrl("realisations/B2.jpg"),
                alt: "real1",
                caption: {
                    title: "Brimont",
                    text: "Contruction d’une maison individuelle - Architecte : REGULAR ARCHITECTURE "
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/B2_HQ01.jpg"),
                        alt: "B2_HQ01",
                        title: "Brimont - Jardin"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B2_HQ02.jpg"),
                        alt: "B2_HQ02",
                        title: "Brimont - Rue"
                    },
                ]
            },
            {
                src: getImageUrl("realisations/B3.jpg"),
                alt: "real2",
                caption: {
                    title: "Beaumont-sur-Vesle",
                    text: "Extension et aménage interieure"
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/B3_HQ01.jpg"),
                        alt: "B3_HQ01",
                        title: "perspective Salle à manger"
                    },
                    {
                        src: getImageUrl("realisations/HQ/B3_HQ02.jpg"),
                        alt: "B3_HQ02",
                        title: "perspective Salon"
                    },
                ]
            },
            {
                src: getImageUrl("realisations/C1.jpg"),
                alt: "real2",
                caption: {
                    title: "Reims",
                    text: "Rénovation d’une maison d’habitation - Architecte : REGULAR ARCHITECTURE"
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/C1_HQ01.jpg"),
                        alt: "C1_HQ01",
                        title: "Perspective interieure salle de bain"
                    },
                    {
                        src: getImageUrl("realisations/HQ/C1_HQ02.jpg"),
                        alt: "C1_HQ02",
                        title: "Perspective interieure salle de douche"
                    },
                    {
                        src: getImageUrl("realisations/HQ/C1_HQ03.jpg"),
                        alt: "C1_HQ03",
                        title: "Perspective interieure salon"
                    },
                ]
            },
            {
                src: getImageUrl("realisations/C2.jpg"),
                alt: "real3",
                caption: {
                    title: "Saint-Brice-Courcelles",
                    text: "Construction d'un bâtiment industriel"
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/C2_HQ.jpg"),
                        alt: "C2_HQ",
                        title: "Perspective pour PC"
                    },
                     {
                        src: getImageUrl("realisations/HQ/C2_HQ02.jpg"),
                        alt: "C2_HQ01",
                        title: "Perspective dessus"
                    },
                     {
                        src: getImageUrl("realisations/HQ/C2_HQ03.jpg"),
                        alt: "C2_HQ03",
                        title: "Perspective entrée"
                    },
                     {
                        src: getImageUrl("realisations/HQ/C2_HQ04.jpg"),
                        alt: "C2_HQ04",
                        title: "Perspective rue"
                    },
                ],
                appLink: "https://client.tapetum.fr/C2/"
            },
            {
                src: getImageUrl("realisations/C3.jpg"),
                alt: "real1",
                caption: {
                    title: "Tinqueux",
                    text: "Construction d’une maison d’habitation"
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/C3_HQ.jpg"),
                        alt: "C3_HQ",
                        title: "Perspective exterieure"
                    },
                ]
            },
                        {
                src: getImageUrl("realisations/D1.jpg"),
                alt: "real3",
                caption: {
                    title: "Nogent-sur-Marne",
                    text: "Résidence sur Nogent-sur-Marne"
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/D1_HQ.jpg"),
                        alt: "D1_HQ",
                        title: "Perspective rue"
                    },
                    {
                        src: getImageUrl("realisations/HQ/D1_HQ02.jpg"),
                        alt: "D1_HQ02",
                        title: "Perpective arrière"
                    },
                ],
            },
            {
                src: getImageUrl("realisations/D2.jpg"),
                alt: "real3",
                caption: {
                    title: "Belle Epoque",
                    text: "Visite VR"
                },
                                hq: [
                    {
                        src: getImageUrl("realisations/HQ/D2-a.jpg"),
                        alt: "D2-a",
                        title: "Salle à manger"
                    },
                    {
                        src: getImageUrl("realisations/HQ/D2-b.jpg"),
                        alt: "D2-b",
                        title: "Salon"
                    },
                                        {
                        src: getImageUrl("realisations/HQ/D2-c.jpg"),
                        alt: "D2-c",
                        title: "Chambre parentale"
                    },
                    {
                        src: getImageUrl("realisations/HQ/D2-d.jpg"),
                        alt: "D2-d",
                        title: "Salle de bain"
                    },
                                        {
                        src: getImageUrl("realisations/HQ/D2-e.jpg"),
                        alt: "D2-e",
                        title: "Cuisine"
                    },
                    {
                        src: getImageUrl("realisations/HQ/D2-f.jpg"),
                        alt: "D2-f",
                        title: "Chambre"
                    },
                                        {
                        src: getImageUrl("realisations/HQ/D2-g.jpg"),
                        alt: "D2-g",
                        title: "Salle de bain"
                    },
                    {
                        src: getImageUrl("realisations/HQ/D2-h.jpg"),
                        alt: "D2-h",
                        title: "Salle de bain"
                    },
                ],
            appLink: "https://client.tapetum.fr/BelleEpoque/301A-VR/"
            },
            {
                src: getImageUrl("realisations/D3.jpg"),
                alt: "real2",
                caption: {
                    title: "Aix en provence",
                    text: "Amenagement exterieur - Architecte : REGULAR ARCHITECTURE"
                },
                hq: [
                    {
                        src: getImageUrl("realisations/HQ/D3_HQ01.jpg"),
                        alt: "D3_HQ01",
                        title: "Aix en provence - Permis de construire"
                    },
                    {
                        src: getImageUrl("realisations/HQ/D3_HQ02.jpg"),
                        alt: "D3_HQ02",
                        title: "Aix en provence - Dessus"
                    },
                ]
            }]
        }
    }
}
</script>

<style scoped>
#realisations h1 {
    font-size: 34px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    text-transform: uppercase;
}

#realisationGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    gap: 8px;
}

#realisationGrid:deep(.vel-modal) {
    background: rgba(255, 255, 255, 0.85);
}

#realisationGrid:deep(.vel-img-title) {
    color: var(--black);
    font-size: 13px;
}

#realisationGrid:deep(.vel-modal .vel-icon) {
    fill: var(--black);
}

@media only screen and (min-width: 768px) {
    #realisationGrid {
        grid-template-columns: 49.75% 49.75%;
        gap: 0.5%;
    }
}

@media only screen and (min-width: 1280px) {
    #realisationGrid {
        grid-template-columns: 33% 33% 33%;
        gap: 0.5%;
    }
}
</style>
