<template>
    <footer>
        <div class="copy-social">
            <div
                id="copyright"
            >Copyright © 2022 | tapetum.fr | Guillaume CUNIS | tous droits réservés</div>
            <div class="social-links">
                <a href="https://fr.linkedin.com/in/guillaume-cunis-3b518a38">
                    <i class="fab fa-linkedin-in"></i>
                </a>
                <a href="https://fr-fr.facebook.com/TapetumCreation/">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/tapetum_gllm/">
                    <i class="fab fa-instagram"></i>
                </a>
            </div>
        </div>
        <button id="mentions" v-on:click="openMentionsModal">Mentions légales</button>
    </footer>

    <Modal v-bind:id="`mentions-modal`" v-bind:content="mentions" v-bind:title="`Mentions Légales`">
        <p>
            Tapetum Création - Guillaume CUNIS
            <br />7b place Charles de Gaulle - 51370 Saint-Brice-Courcelles
            <br />
            <a href="mailto:guillaume.cunis@gmail.com">guillaume.cunis@gmail.com</a>
            <br />Siret : 837 799 568 00022
            <br />Tel :
            <a href="callto:0637958290">06 37 95 82 90</a>
            <br />
        </p>
        <h3>EDITEUR DU SITE</h3>
        <p>
            Ce site est édité par Tapetum création
            <br />Création et mise à jour des contenus : Guillaume CUNIS
            <br />Hébergement du site : OVH – 2 rue Kellerman 59100 Roubaix
            <br />Crédits photos : Tapetum Création
        </p>

        <h3>Propriété intellectuelle</h3>

        <p>
            Guillaume CUNIS est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur
            tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons,
            logiciels.
        </p>

        <p>
            Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du
            site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de :
            Guillaume CUNIS
        </p>

        <p>Toute infraction à ces droits peut entraîner des poursuites civiles ou pénales.</p>
    </Modal>
</template>

<script>
import Modal from './Modal';
export default {
    name: "Footer",
    components: { Modal },
    data() {
        return {
            mentions: `

`
        }
    },
    methods: {
        openMentionsModal() {
            document.getElementById("mentions-modal").classList.add("visible");
        }
    }
}
</script>

<style scoped>
p {
    margin: 13px 0;
    line-height: 1.1em;
}
footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #7b7b7b;
    padding: 25px 60px 25px 45px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0.07em;
    text-align: center;
    gap: 5px;
}

footer #copyright,
footer #mentions {
    color: white;
    font-size: 13px;
    font-family: Urbanist;
}

footer #mentions {
    text-decoration: none;
    background: none;
    border: none;
    outline: none;
    font-size: 13px;
    cursor: pointer;
}

footer #mentions:hover {
    text-decoration: underline;
}

footer .copy-social {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-items: center;
}

footer .social-links {
    display: flex;
    flex-direction: row;
    gap: 13px;
    justify-content: center;
}

footer .social-links a {
    color: var(--white);
    transition: color ease 0.2s;
}

footer .social-links a:hover {
    color: var(--light-text);
}

@media only screen and (min-width: 900px) {
    footer {
        flex-direction: row;
        text-align: left;
    }

    footer .copy-social {
        flex-direction: row;
        align-items: center;
        gap: 34px;
    }
}
</style>
