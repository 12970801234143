<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65">
    <g>
    <path
        class="a"
        d="M32.3,2.3L10.9,14v22.6l21.5,14,21.5-13.8V14L32.3,2.3Zm-1.3,44.8l-17.9-11.5V17l17.9,10.6v19.6Zm1.2-22L14.4,14.5,32.3,4.8l17.8,9.7-17.9,10.6Zm19.2,10.5l-18.1,11.6V27.4l18.1-10.5v18.6Z"
        />
      <path
        class="a"
        d="M41.6,56.4c-.3,0-.6-.2-.7-.6,0-.4,.2-.8,.5-.8,10.9-2.3,18.2-8.4,18.2-15.1s-2.5-7.7-6.9-10.6c-.3-.2-.4-.7-.2-1,.2-.3,.7-.4,1-.2,4.9,3.2,7.5,7.4,7.5,11.8,0,7.5-7.8,14.1-19.3,16.5,0,0,0,0-.1,0Z"
        />
          <path
        class="a"
        d="M33.1,56.7s0,0,0,0h0c0,0,0-.2,0-.3h0c0,0,0-.2-.1-.2h0l-7-6.7c-.3-.3-.7-.3-1,0-.3,.3-.3,.7,0,1l5.7,5.4c-14.2-.5-25.5-7.5-25.5-16s2.6-8,7.4-11c.3-.2,.4-.6,.2-1-.2-.3-.6-.4-1-.2-5.2,3.3-8.1,7.6-8.1,12.2,0,9.3,11.9,16.8,26.9,17.4l-6.1,5.2c-.3,.3-.3,.7,0,1,.1,.1,.3,.2,.5,.2s.4,0,.5-.2l7.5-6.4h0c0,0,0-.1,.1-.2,0,0,0,0,0,0,0,0,0-.1,0-.2Z"
        />
    </g>
  </svg>
</template>

<script>
export default {
    name: "Cube360"
}
</script>

<style scoped>
li svg path {
    fill: var(--white);
}
</style>


  
