<template>
    <section id="services">
        <h1>SERVICES</h1>
        <ul>
            <Service
                v-for="service in services"
                v-bind:title="service.title"
                v-bind:content="service.description"
                v-bind:icon="service.icon"
                v-bind:key="service.id"
            ></Service>
        </ul>
    </section>
</template>

<script>
import Service from "./Service.vue"

export default {
    name: "Services",
    components: { Service },
    data() {
        return {
            services: [
                {
                    id: 1,
                    icon: "cube",
                    title: "Perspective 3D",
                    description: "Mise en volume des plans en incluant : texture, lumière, environnement et ambiance"
                },
                {
                    id: 2,
                    icon: "cube360",
                    title: "Turn 360°",
                    description: "Manipuler et tourner autour du projet afin de mieux appréhender le volume du projet"
                },
                {
                    id: 3,
                    icon: "360",
                    title: "Balade 360°",
                    description: "Immersion dans le projet par des images panoramique à 360°"
                },
                {
                    id: 4,
                    icon: "vr",
                    title: "Casque VR",
                    description: "Immersion total à l’aide d’un caque de réalité virtuelles en déplacement libre"
                },
            ]
        }
    }
}
</script>

<style scoped>
#services {
    margin-top: 34px;
    background-color: var(--primary);
    color: white;
    padding: 34px 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
}

#services h1 {
    font-size: 34px;
    text-align: center;
    margin-top: 0;
}

ul {
    margin-top: 0px;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    gap: 13px;
}
span {
    font-weight: lighter;
}
@media only screen and (min-width: 768px) {
    #services {
        margin-top: 55px;
    }

    ul {
        margin-top: 21px;
        max-width: 1440px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 55px;
    }
}
</style>
