<template>
    <div v-bind:id="id" class="modal-splash">
        <div class="modal-layout">
            <div class="modal">
                <div class="modal-header">
                    <h2 class="modal-title">{{ title }}</h2>
                    <button v-on:click="close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "Modal",
    props: ["id", "title"],
    data() {
        return {}
    },
    methods: {
        close() {
            document.getElementById(this.id).classList.remove("visible")
        }
    }
}
</script>


<style scoped>
.modal-splash {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -10;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 21px 34px;
    transition: all ease 0.2s;
}

.modal-splash.visible {
    display: flex;
    opacity: 1;
    z-index: 10;
}

.modal-layout {
    z-index: 10;
    opacity: 0;
    transition: all ease 0.2s;
    margin: 0 auto;
}

.modal-splash.visible .modal-layout {
    opacity: 1;
}

.modal {
    background: white;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 21px 0;
    box-shadow: 8px 8px 5px var(--black_trans);
}

.modal-header {
    padding: 0px 34px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.modal-header .modal-title {
    color: var(--black);
    font-weight: 300;
}

.modal-header button {
    background: none;
    color: var(--black);
    padding: 8px 8px;
    border: none;
    outline: none;
    font-size: 21px;
    cursor: pointer;
    transition: transform ease 0.2s;
}

.modal-header button:hover {
    transform: rotateZ(180deg);
}

.modal-body {
    padding: 0 34px;
    color: var(--black);
}
</style>