<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65">
        <g>
            <path
                class="a"
                d="M27.4,34.2c0,.5,0,1-.3,1.4s-.4,.8-.8,1c-.3,.3-.7,.5-1.2,.7-.5,.2-1,.2-1.6,.2s-.7,0-1,0c-.3,0-.6-.1-.9-.2-.3,0-.5-.2-.6-.3-.2,0-.3-.2-.3-.2s0,0,0-.1c0,0,0,0,0-.1,0,0,0-.1,0-.2,0,0,0-.2,0-.3,0-.2,0-.3,0-.4,0,0,0-.1,.2-.1s.2,0,.3,.1c.2,0,.4,.2,.6,.3,.2,.1,.5,.2,.9,.3,.3,0,.7,.1,1.1,.1s.7,0,1-.1,.5-.2,.7-.4c.2-.2,.3-.4,.4-.6s.1-.5,.1-.8,0-.6-.2-.8-.3-.5-.6-.7c-.2-.2-.5-.3-.9-.4-.4,0-.8-.1-1.2-.1h-1.1s0,0-.1,0c0,0,0,0-.1,0,0,0,0-.1,0-.2,0,0,0-.2,0-.3s0-.2,0-.3c0,0,0-.1,0-.2,0,0,0,0,.1,0,0,0,0,0,.1,0h1c.4,0,.7,0,1-.1s.6-.2,.8-.4c.2-.2,.4-.4,.5-.7s.2-.5,.2-.9,0-.4-.1-.7c0-.2-.2-.4-.3-.5s-.3-.3-.6-.4c-.2,0-.5-.1-.8-.1s-.6,0-.9,.2c-.3,.1-.5,.2-.7,.3s-.4,.2-.5,.3c-.1,.1-.2,.1-.3,.1s0,0-.1,0c0,0,0,0,0,0,0,0,0-.1,0-.2,0,0,0-.2,0-.3s0-.2,0-.2,0-.1,0-.2c0,0,0,0,0-.1s0,0,.1-.1c0,0,.2-.1,.3-.2,.2-.1,.4-.2,.6-.3,.2-.1,.5-.2,.8-.3s.7-.1,1-.1,1,0,1.4,.2c.4,.1,.7,.3,1,.6,.3,.2,.5,.5,.6,.9,.1,.3,.2,.7,.2,1.1s0,.7-.1,1-.2,.6-.4,.8c-.2,.2-.4,.4-.6,.6-.3,.2-.6,.3-.9,.3h0c.4,0,.7,.2,1,.3,.3,.2,.6,.3,.8,.6,.2,.2,.4,.5,.5,.8,.1,.3,.2,.6,.2,1Z"
            />
            <g>
                <path
                    class="a"
                    d="M36.7,33.8c0,.5,0,1-.2,1.4-.1,.5-.4,.9-.7,1.2s-.7,.6-1.2,.8c-.5,.2-1,.3-1.7,.3s-.9,0-1.2-.2c-.4-.1-.7-.3-.9-.5-.3-.2-.5-.5-.7-.8-.2-.3-.3-.7-.4-1.1-.1-.4-.2-.8-.2-1.3,0-.5,0-1,0-1.5s0-1,0-1.4c0-.5,.1-1,.3-1.4,.1-.5,.3-.9,.5-1.3s.5-.7,.8-1c.3-.3,.7-.5,1.2-.7,.5-.2,1-.3,1.7-.3s.4,0,.6,0c.2,0,.4,0,.6,.1s.3,0,.5,.1c.1,0,.2,0,.2,.1,0,0,0,0,0,.1,0,0,0,0,0,.1,0,0,0,0,0,.1,0,0,0,.1,0,.2s0,.2,0,.3c0,0,0,.1,0,.2s0,0,0,.1c0,0,0,0-.1,0s-.1,0-.3,0c-.1,0-.2,0-.4-.1-.2,0-.3,0-.6-.1-.2,0-.5,0-.8,0-.5,0-1,.1-1.4,.3-.4,.2-.7,.5-.9,.9-.2,.4-.4,.8-.5,1.3-.1,.5-.2,1-.2,1.5,.1,0,.3-.2,.5-.3,.2,0,.4-.2,.6-.2,.2,0,.5-.1,.7-.2s.5,0,.8,0c.6,0,1.1,0,1.5,.2,.4,.2,.7,.4,1,.7,.3,.3,.4,.6,.5,1,.1,.4,.2,.8,.2,1.3Zm-1.5,.1c0-.3,0-.6,0-.9,0-.3-.2-.5-.3-.7-.2-.2-.4-.3-.6-.4-.3-.1-.6-.2-.9-.2s-.4,0-.6,0c-.2,0-.4,0-.6,.2-.2,0-.4,.1-.6,.2-.2,0-.3,.2-.5,.3,0,.7,0,1.4,.1,1.9,0,.5,.2,.9,.4,1.2,.2,.3,.4,.5,.7,.6s.6,.2,.9,.2,.7,0,.9-.2c.3-.1,.5-.3,.7-.6s.3-.5,.4-.8c0-.3,.1-.6,.1-.9Z"
                />
                <path
                    class="a"
                    d="M45.6,31.8c0,.9,0,1.6-.2,2.3-.1,.7-.4,1.3-.7,1.8-.3,.5-.7,.9-1.2,1.2-.5,.3-1.1,.4-1.9,.4s-1.3-.1-1.8-.4c-.5-.2-.9-.6-1.1-1.1-.3-.5-.5-1.1-.6-1.8s-.2-1.5-.2-2.4,0-1.6,.2-2.3c.1-.7,.4-1.3,.7-1.8s.7-.9,1.2-1.2c.5-.3,1.1-.4,1.8-.4s1.3,.1,1.8,.4c.5,.2,.9,.6,1.1,1.1,.3,.5,.5,1.1,.6,1.8,.1,.7,.2,1.5,.2,2.4Zm-1.5,.1c0-.6,0-1.1,0-1.5,0-.4,0-.8-.2-1.2s-.2-.6-.3-.9c-.1-.2-.3-.4-.4-.6-.2-.2-.4-.3-.6-.3-.2,0-.5-.1-.7-.1-.5,0-.9,.1-1.2,.3-.3,.2-.5,.5-.7,.9-.2,.4-.3,.9-.4,1.4s0,1.1,0,1.7,0,1.5,.1,2.1c0,.6,.2,1.1,.4,1.4,.2,.4,.4,.6,.7,.8,.3,.2,.6,.2,1,.2s.6,0,.8-.2c.2-.1,.5-.3,.6-.4,.2-.2,.3-.4,.4-.7,.1-.3,.2-.6,.3-.9,0-.3,.1-.7,.1-1.1,0-.4,0-.8,0-1.2Z"
                />
                <path
                    class="a"
                    d="M45.6,23.8c0,.2,0,.4-.1,.6s-.2,.3-.3,.5c-.1,.1-.3,.2-.5,.3-.2,0-.4,.1-.6,.1s-.4,0-.6-.1c-.2,0-.3-.2-.5-.3s-.2-.3-.3-.5c0-.2-.1-.4-.1-.6s0-.4,.1-.6c0-.2,.2-.3,.3-.5,.1-.1,.3-.2,.5-.3,.2,0,.4-.1,.6-.1s.4,0,.6,.1c.2,0,.4,.2,.5,.3,.1,.1,.2,.3,.3,.5s.1,.4,.1,.6Zm-.8,0c0-.1,0-.2,0-.3,0,0,0-.2-.2-.3,0,0-.1-.1-.2-.2,0,0-.2,0-.3,0s-.2,0-.3,0c0,0-.2,0-.2,.2,0,0-.1,.2-.2,.3s0,.2,0,.3,0,.2,0,.3,0,.2,.2,.3c0,0,.2,.1,.3,.2s.2,0,.3,0,.2,0,.3,0c0,0,.2-.1,.2-.2,0,0,.1-.2,.2-.3,0,0,0-.2,0-.3Z"
                />
                <path
                    class="a"
                    d="M33.1,56.9c-8.3,0-15.1-11.7-15.1-26.1S24.8,4.8,33.1,4.8s15.1,11.7,15.1,26.1-.3,6.2-.9,9c-.1,.5-.6,.7-1,.6-.5-.1-.7-.6-.6-1,.6-2.7,.9-5.2,.9-8.7,0-13.4-6-24.3-13.4-24.3s-13.4,10.9-13.4,24.3,6,24.3,13.4,24.3,8.8-5.4,10.6-10c.2-.4,.7-.7,1.1-.5,.4,.2,.7,.7,.5,1.1-2.8,7.3-7,11.1-12.2,11.1Z"
                />
                <path
                    class="a"
                    d="M33.1,45.4c-14.3,0-26-6.8-26-15.1s4.1-9.5,10.9-12.3c.4-.2,.9,0,1.1,.5s0,.9-.5,1.1c-6.1,2.5-9.8,6.5-9.8,10.7,0,7.4,10.9,13.4,24.3,13.4s24.3-6,24.3-13.4-10.9-13.4-24.3-13.4-6.3,.3-9.1,1c-.5,.1-.9-.2-1-.6-.1-.5,.2-.9,.6-1,3.4-.9,6.1-1,9.6-1,14.3,0,26,6.8,26,15.1s-11.7,15.1-26,15.1Z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "View360"
}
</script>

<style scoped>
li svg path {
    fill: var(--white);
}
</style>
