<template>
  <section id="presentation">
    <p class="text-center text-black">{{ presentation }}</p>
  </section>
</template>

<script>
export default {
  name: 'Presentation',
  data() {
    return {
      presentation: `Mon but ? Apporter et transmettre une nouvelle dimension à vos projets grâce à des supports 3D de qualité. Comment je vous l'apporte ? Grâce à plusieurs outils technologiques :  image fixe, application web, balade VR, 360° ... Vous avez un problème ? Je suis là pour créer avec vous une réponse sur mesure pour une mise en valeur de vos créations.`,
    }
  }
}
</script>

<style scoped>
#presentation {
  padding: 55px 34px;
  line-height: 1.75em;
}

#presentation p {
  margin: 0 auto;
  max-width: 987px;
  font-size: 21px;
  color: var(--black);
}

@media only screen and (min-width: 1280px) {
  #presentation {
    padding: 55px 0;
  }
}
</style>
